/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

/* Swiper module */
// TODO: Did not work, so import the hole bundle
// @import '~swiper/scss';
// @import '~swiper/scss/autoplay';
// @import '~swiper/scss/keyboard';
// @import '~swiper/scss/pagination';
// @import '~swiper/scss/navigation';
// @import '~swiper/scss/scrollbar';
// @import '~swiper/scss/zoom';
// @import '~swiper/scss/effect-fade';
// @import '~swiper/scss/effect-creative';
// @import '~@ionic/angular/css/ionic-swiper';
@import 'swiper/css/bundle';

@import 'variables';

// Import locale fonts
@import 'fonts.css';

@mixin responsiveHeader($headerHeight, $backgroundImgWidth) {
	ion-header:not(.header-collapse-condense) {
		height: calc(#{$headerHeight} + var(--ion-safe-area-top, 0));

		ion-toolbar {
			background-size: #{$backgroundImgWidth} !important;
		}
	}
	ion-modal {
		ion-header:not(.header-collapse-condense) {
			height: calc(#{$headerHeight} + var(--ion-safe-area-top, 0));
		}

		&.ios {
			ion-header:not(.header-collapse-condense) {
				ion-toolbar:first-of-type {
					padding: 0;
				}
			}
		}
		&.ios.modal-card {
			ion-header:not(.header-collapse-condense) {
				height: #{$headerHeight};
			}
		}
	}
	ion-content {
		--offset-top: calc(
			#{$headerHeight} + var(--ion-safe-area-top, 0)
		) !important;
	}
}

// Custom colors
.ion-color-text {
	--ion-color-base: var(--ion-color-text);
	--ion-color-base-rgb: var(--ion-color-text-rgb);
	--ion-color-contrast: var(--ion-color-text-contrast);
	--ion-color-contrast-rgb: var(--ion-color-text-contrast-rgb);
	--ion-color-shade: var(--ion-color-text-shade);
	--ion-color-tint: var(--ion-color-text-tint);
}

// TODO: Workaround for Angular tabindex blue border bug. May be fixed in newer Angular versions
ion-button:focus,
ion-card:focus,
a:focus,
button:focus {
	outline: none;
}

body.hide-app {
	background-color: transparent !important;

	> app-root > ion-app {
		> ion-router-outlet,
		> ion-modal {
			visibility: hidden;

			&.visible {
				visibility: visible;
				background-color: transparent;

				--width: 100%;
				--max-width: 100%;
				--height: 100%;
				--max-height: 100%;

				&::part(content) {
					background-color: transparent;
				}
				&.fullscreen {
					--width: 100% !important;
					--max-width: 100% !important;
					--height: 100% !important;
					--max-height: 100% !important;
				}
			}
		}
	}
}

body {
	font-family: 'Montserrat', sans-serif;
	font-size: 14px;
}

:root {
	--ion-font-family: 'Montserrat', sans-serif;
	--ion-font-size: 14px;

	.logo {
		height: 3rem;
		background: transparent url('assets/images/logo.png') no-repeat center
			center;
		background-size: 100%;
	}

	.dark .logo {
		background-image: url('assets/images/logo_dark.png');
	}

	.title-large {
		font-size: 1.6rem;
		font-family: 'Playfair Display', serif;
		font-weight: 300;

		.toolbar-title {
			white-space: normal;
			overflow: visible;
		}
	}

	@include responsiveHeader(70px, 1090px);

	ion-header:not(.header-collapse-condense) {
		transition: height 0.15s;

		ion-toolbar {
			height: 100%;
			--background: none;
			background: transparent url('assets/images/header_bgr.svg')
				no-repeat center bottom;

			&,
			ion-card ion-item {
				--color: #ffffff;
			}
		}

		ion-title {
			.logo {
				height: 26px;
				margin-top: 5px;
				display: none;
			}
		}

		&.header-collapse-condense-inactive:not(.static),
		&.home {
			ion-toolbar {
				background-image: url('assets/images/header_home_bgr.svg');
			}
		}

		&.header-collapse-condense-inactive:not(.static) {
			ion-buttons {
				margin-top: 18px;
				margin-left: 12px;
				margin-right: 5px;

				ion-button,
				ion-back-button {
					--ion-color-base: var(--ion-color-primary) !important;
				}
				ion-back-button,
				.back-button {
					margin-left: 0;
				}
			}
		}
	}
	ion-back-button,
	.back-button {
		width: 32px;
		margin-left: 2px;
		margin-right: 0;
		--padding-start: 0;
		--padding-end: 0;

		&.ios {
			margin-left: 10px;
		}
		ion-icon {
			width: 31.44px;
			height: 31.44px;
			margin-left: -4px;
			margin-right: -5px;
		}
	}
	ion-app.md ion-header.ios {
		ion-back-button,
		.back-button {
			&.ios {
				margin-left: 2px;
			}
		}
		&:not(.header-collapse-condense) {
			&.header-collapse-condense-inactive:not(.static) {
				ion-buttons {
					ion-back-button,
					.back-button {
						margin-left: 0;
					}
				}
			}
		}
	}

	.dark {
		ion-header:not(.header-collapse-condense) {
			ion-toolbar {
				background-image: url('assets/images/header_bgr_dark.svg');
			}

			&.header-collapse-condense-inactive:not(.static),
			&.home {
				ion-toolbar {
					background-image: url('assets/images/header_home_bgr_dark.svg');
				}
			}

			&.header-collapse-condense-inactive:not(.static) {
				ion-buttons {
					ion-button,
					ion-back-button {
						--ion-color-base: var(--ion-text-color) !important;
					}
				}
			}
		}
	}

	ion-modal {
		&.ios.modal-card {
			ion-header {
				ion-toolbar {
					padding-top: 0px;
				}
			}
		}
		&.ios ion-header.header-collapse-condense ion-toolbar {
			padding-right: var(--ion-safe-area-right);
			padding-left: var(--ion-safe-area-left);
		}
		&.transparent {
			--width: 100%;
			--max-width: 100%;
			--height: 100%;
			--max-height: 100%;

			&,
			ion-content {
				--offset-top: var(--ion-safe-area-top, 0);
				--offset-bottom: var(--ion-safe-area-bottom, 0);
				--background: rgba(var(--alpha-color-rgb), 0.7);
			}

			&::part(content) {
				--background: transparent;
				backdrop-filter: blur(4px);
			}
		}
		&.large {
			--width: 900px;
			--max-width: 100%;
		}
	}

	ion-footer,
	ion-tab-bar {
		box-shadow: 0px 0px 6px var(--ion-shadow-color);
	}

	ion-footer {
		ion-toolbar {
			--padding-start: var(--ion-padding, 16px);
			--padding-end: var(--ion-padding, 16px);
			--padding-top: var(--ion-padding, 16px);
			--padding-bottom: var(--ion-padding, 16px);
			--background: var(--footer-background-color);

			ion-button {
				margin: 0;
			}
		}
	}

	ion-tab-bar {
		--border: none;

		ion-tab-button {
			> ion-icon {
				height: 22px;
			}

			ion-icon.selected {
				display: none;
			}

			&.tab-selected {
				ion-icon {
					display: none;

					&.selected {
						display: block;
					}
				}
			}
		}
		.logo {
			height: 36px;
			background-image: url('assets/images/logo_icon_white.png');
			display: none;
		}
	}
	.dark ion-tab-bar {
		.logo {
			background-image: url('assets/images/logo_icon_white.png');
		}
	}

	ion-menu.sidemenu {
		ion-list {
			padding-top: 0px;
			padding-bottom: var(--ion-safe-area-bottom, 0);

			ion-list-header {
				height: 44px;
				margin-top: var(--ion-safe-area-top, 0);
			}

			ion-item.active-menu-item {
				&,
				ion-icon {
					color: var(--ion-color-primary-contrast);
					--color: var(--ion-color-primary-contrast);
					--background: var(--ion-color-primary);
					--border-color: var(--ion-color-primary);
				}
			}
		}

		&,
		ion-content {
			--background: var(
				--ion-item-background,
				var(--ion-background-color, #fff)
			);
		}
	}

	// Hide scrollbar for Chrome, Safari and Opera
	ion-content::part(scroll)::-webkit-scrollbar {
		display: none;
	}
	.inner-scroll.scroll-y::-webkit-scrollbar {
		display: none;
	}
	*::-webkit-scrollbar {
		display: none !important;
	}
	// Hide scrollbar for IE, Edge and Firefox
	ion-content::part(scroll) {
		-ms-overflow-style: none; // IE and Edge
		scrollbar-width: none; // Firefox
	}
	ion-content.scroll-disabled:not(.infinite-scroll) {
		--overflow: hidden;
	}
	cdk-virtual-scroll-viewport.cdk-virtual-scroll-orientation-vertical
		.cdk-virtual-scroll-content-wrapper {
		width: 100%;
	}

	ion-thumbnail ion-button,
	ion-button {
		&:not(.button-round) {
			--border-radius: 8px;
		}
		&.button-disabled {
			opacity: 0.2;
		}
		&.button-outline {
			--border-width: 1px;
		}
	}
	ion-button.link {
		height: auto;
		font-size: inherit;
		font-weight: inherit;
		text-transform: none;
		margin: 0;
		--padding-start: 0;
		--padding-end: 0;
		--border-radius: 0;

		&.button-small {
			font-size: 13px;
		}
	}

	.main-button,
	.main-buttons {
		margin-top: $size_l;
		margin-bottom: $size_xl;
	}

	ion-content ion-button:not(.button-clear),
	ion-footer ion-button:not(.button-clear) {
		--box-shadow: var(--shadow);
	}

	.dark {
		ion-content ion-button:not(.button-clear),
		ion-footer ion-button:not(.button-clear) {
			--box-shadow: 0 2px 6px 0px rgba(0, 0, 0, 0.5);
		}
	}

	.clickable {
		cursor: pointer;
	}

	.footer-spacer {
		height: $size_xl;
		line-height: 0.01px;
		display: block;
	}

	ion-footer {
		ion-toolbar {
			ion-grid.ion-no-padding > ion-row {
				ion-col:first-child {
					ion-button {
						margin-right: $size_s;
					}
				}

				ion-col:last-child {
					ion-button {
						margin-left: $size_s;
					}
				}
			}
		}
	}

	ion-toolbar .expanded-buttons {
		display: flex;
		justify-content: center;

		ion-button {
			flex: 1 1 auto;
			margin: 0 5px;

			&:first-of-type {
				margin-left: 0;
			}

			&:last-of-type {
				margin-right: 0;
			}
		}
	}

	.card-content-ios {
		font-size: var(--ion-font-size);
	}

	ion-card {
		box-shadow: var(--shadow);

		.card-img-default {
			background: url('assets/images/banner.png') center center/100%
				no-repeat;

			img {
				width: 100%;
				display: block;
			}
		}

		ion-card-title {
			font-size: 18px;
		}

		ion-card-content.title-aligned {
			margin-left: 56px;
		}

		&.ios {
			ion-card-content.title-aligned {
				margin-left: 47px;
			}
		}

		> ion-item:first-child,
		> ion-radio-group > ion-item:first-child,
		> ion-reorder-group > ion-item:first-child,
		> ion-reorder-group > ion-item-sliding:first-child ion-item,
		> ion-item-sliding:first-child ion-item,
		ion-item.first-child {
			&::part(native) {
				border-top-left-radius: 4px;
				border-top-right-radius: 4px;
			}
			&.ios::part(native) {
				border-top-left-radius: 8px;
				border-top-right-radius: 8px;
			}
		}
		> ion-item:last-child,
		> ion-radio-group > ion-item:last-child,
		> ion-reorder-group > ion-item:last-child,
		> ion-reorder-group > ion-item-sliding:last-child ion-item,
		> ion-item-sliding:last-child ion-item,
		ion-item.last-child {
			&::part(native) {
				border-bottom-left-radius: 4px;
				border-bottom-right-radius: 4px;
			}
			&.ios::part(native) {
				border-bottom-left-radius: 8px;
				border-bottom-right-radius: 8px;
			}
		}
	}

	.dark {
		ion-card {
			.card-img-default {
				background-image: url('assets/images/banner_dark.png');
			}
		}
	}

	ion-grid.card-grid,
	ion-grid.card-grid-large {
		> ion-row > ion-col {
			display: flex;

			ion-card,
			.card-container {
				max-width: 100%;
				flex: 1 1 auto;
			}
			.card-container {
				display: flex;
			}
		}
	}
	ion-grid.card-grid {
		margin-top: -5px;
		margin-bottom: -5px;

		> ion-row > ion-col {
			ion-card {
				margin-top: 5px;
				margin-bottom: 5px;
			}
		}
		&.ios {
			margin-top: -12px;
			margin-bottom: -12px;

			> ion-row > ion-col {
				ion-card {
					margin-top: 12px;
					margin-bottom: 12px;
				}
			}
		}
	}
	ion-content > ion-grid.card-grid:first-child {
		margin-top: 5px;

		&.ios {
			margin-top: 12px;
		}
	}
	ion-grid.card-grid-large {
		> ion-row > ion-col {
			ion-card {
				margin-top: $size_m;
				margin-bottom: $size_m;
			}
		}
	}

	ion-grid.inner {
		margin: calc(
			-1 * var(--ion-grid-column-padding-xs, var(--ion-grid-column-padding, 5px))
		);

		&:not(.ion-padding):not(.ion-padding-horizontal) {
			padding-left: 0;
			padding-right: 0;
		}
		&:not(.ion-padding):not(.ion-padding-vertical) {
			padding-top: 0;
			padding-bottom: 0;
		}
	}

	ion-list,
	ion-card {
		ion-list-header {
			min-height: 0px;

			h6 {
				font-size: var(--ion-font-size);
				font-weight: 600;
				margin: 16px 0 0 0;
			}
		}

		ion-item {
			font-size: var(--ion-font-size);

			&.md > ion-icon {
				margin-top: 14px;
			}

			&.item-interactive.ios {
				--highlight-height: 1px;

				> ion-icon {
					margin-top: 33.5px;
				}
			}

			&.header {
				font-size: 18px;
				font-weight: 700;
				line-height: 1.2;
				padding-top: 10px;
				padding-bottom: 6px;

				&.md {
					font-weight: 500;
					color: var(--ion-color-step-850, #262626);
					padding-top: 6px;
				}
			}

			ion-label {
				> label {
					font-size: 90%;
					padding-bottom: $size_s;
					display: block;
				}
			}
		}

		&.list-md.list-inset ion-item.item-interactive {
			--padding-start: 16px;

			> ion-icon {
				margin-right: 16px;
			}
		}

		&.list-inset {
			box-shadow: var(--shadow);

			ion-item-divider.md {
				--background: var(
					--ion-item-background,
					var(--ion-background-color, #fff)
				);
			}
		}

		ion-item-divider.transparent {
			--background: var(
				--ion-item-background,
				var(--ion-background-color, #fff)
			);
		}
	}

	ion-list + ion-note {
		margin-top: $size_s;
		display: block;
	}

	ion-note + ion-list {
		margin-top: $size_l;
	}

	ion-item {
		font-size: var(--ion-font-size);

		&.transparent {
			--background: transparent;
		}
		&.spacer {
			--min-height: #{$size_m};
		}
	}
	ion-item.not-disabled {
		ion-input .native-input[disabled],
		ion-label {
			opacity: 1 !important;
		}
	}
	ion-input[type='number'] {
		min-width: 30px;

		&.large {
			min-width: 50px;
		}
	}
	ion-input {
		input::-webkit-outer-spin-button,
		input::-webkit-inner-spin-button {
			display: none;
		}
	}

	ion-checkbox {
		&.ios {
			--border-radius: 5px;
		}
	}
	ion-radio {
		&.ios {
			width: 26px;
			height: 26px;
			--color-checked: var(--ion-color-primary-contrast);

			&::part(container) {
				border: 1px solid rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.23);
				border-radius: 50%;
			}
			&.radio-checked::part(container) {
				background-color: var(--ion-color-primary);
			}
		}
	}

	ion-popover {
		--background: var(--ion-overlay-background-color);
		--background-rgb: var(--ion-overlay-background-color-rgb);
		--ion-background-color: var(--ion-overlay-background-color);
		--ion-background-color-rgb: var(--ion-overlay-background-color-rgb);

		&.context-menu {
			--min-width: 260px;

			ion-list-header {
				font-size: inherit;
				color: var(--ion-color-step-500);
			}
			ion-item {
				ion-icon {
					margin-inline-end: $size_m;
				}
			}
		}
		&.filter {
			--min-width: 320px;
		}

		ion-datetime {
			--background: var(--ion-overlay-background-color);
			--background-rgb: var(--ion-overlay-background-color-rgb);
		}
	}

	ion-chip {
		ion-label {
			line-height: 1.1;
		}
		&.chip-disabled {
			opacity: 1;
		}
		&.small {
			max-width: 100%;
			height: 20px;
			font-size: 12px;
			margin: $size_xs 0;
			margin-left: $size_xs;

			ion-icon {
				margin-top: -4px !important;
				height: 15px;

				&:first-child {
					margin-right: $size_xs;
				}
				&:last-child {
					margin-left: $size_xs;
				}
			}
		}
	}

	.chip-field {
		font-size: 14px;

		&.md {
			--min-height: 42px;
			--background: var(--ion-background-color, #fff);
			--border-radius: 2px;
			box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
				0 3px 1px -2px rgba(0, 0, 0, 0.2),
				0 1px 5px 0 rgba(0, 0, 0, 0.12);
		}

		&.ios {
			--min-height: 36px;
			--background: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.07);
			--border-radius: 10px;
		}
	}

	ion-segment {
		ion-segment-button {
			--padding-start: #{$size_m};
			--padding-end: #{$size_m};
			--margin-start: 0;
			--margin-end: 0;
		}
	}

	ion-slides {
		--bullet-background: var(--ion-color-step-550);
		--bullet-background-active: var(--ion-color-primary);

		.swiper-button-prev,
		.swiper-button-next {
			&:after {
				display: none;
			}
		}
		.swiper-pagination-bullet {
			background: var(--bullet-background);
		}
		.swiper-pagination-bullet-active {
			background: var(--bullet-background-active);
		}
	}
	swiper {
		--swiper-theme-color: var(--ion-color-light);
		--swiper-navigation-size: 24px;

		> .swiper-button-prev,
		> .swiper-button-next {
			background-color: rgba(var(--alpha-color-rgb), 0.4);
			padding: 8px 10px;
			box-sizing: content-box;
		}
		> .swiper-button-prev {
			left: 0;
			border-top-right-radius: 6px;
			border-bottom-right-radius: 6px;
		}
		> .swiper-button-next {
			right: 0;
			border-top-left-radius: 6px;
			border-bottom-left-radius: 6px;
		}
	}
	.lightbox-modal {
		swiper {
			height: 100%;
			--swiper-pagination-bullet-inactive-color: var(
				--ion-color-light-shade
			);

			&.swiper-horizontal > .swiper-pagination-bullets {
				bottom: calc(var(--ion-safe-area-bottom, 0px) + 10px);
			}
		}
	}

	.searchbar-input.sc-ion-searchbar-ios,
	.searchbar-input.sc-ion-searchbar-md {
		font-size: 14px;
		padding-right: 16px;
	}

	.alert {
		.alert-wrapper {
			background: var(
				--ion-overlay-background-color,
				var(--ion-background-color)
			) !important;
		}
	}
	.alert-translucent {
		.alert-wrapper {
			background: rgba(
				var(
					--ion-overlay-background-color-rgb,
					var(--ion-background-color-rgb)
				),
				0.9
			) !important;

			&.sc-ion-alert-md {
				background: rgba(
					var(
						--ion-overlay-background-color-rgb,
						var(--ion-background-color-rgb)
					),
					1
				) !important;
			}
		}
	}
	.alert-message ul {
		list-style-type: square;

		li {
			text-align: left;
		}
	}
	ion-alert {
		&.max-size {
			--width: 90%;
			--max-width: 90%;
			--height: 75%;
			--max-height: 75%;

			.alert-radio-group {
				max-height: 100% !important;
			}
		}
		&.text-wrap {
			.alert-radio-label.sc-ion-alert-md,
			.alert-radio-label.sc-ion-alert-ios {
				white-space: normal;
			}
			.alert-tappable.alert-radio {
				height: auto;
				contain: content;
			}
		}
	}

	ion-toast {
		height: calc(100% - 80px);
		color: var(--ion-color-dark-contrast);
		--background: var(--ion-color-dark);
		--ion-background-color-rgb: var(--ion-color-dark-rgb);
		/* Overwrite primary color to use light color for buttons, since background is always dark */
		--ion-color-primary: var(--ion-color-light);

		&.big-icon {
			--max-width: 250px;

			&::part(container) {
				flex-direction: column;
			}
			&::part(icon) {
				width: 48px;
				height: 48px;
				margin: 0;
				margin-top: $size_m;
			}
			&::part(message) {
				text-align: center;
				margin-top: -$size_s;
			}

			&.success {
				color: var(--ion-color-success);
			}
		}
	}

	ion-loading {
		&.backdrop {
			.loading-wrapper {
				display: none;
			}
		}
	}

	app-qr-creator {
		canvas {
			width: 100%;
			display: block;
		}
	}
	app-camera-preview {
		#cameraPreview video {
			height: 100%;
		}
		&.video-landscape {
			#cameraPreview video {
				width: 100%;
				height: auto;
			}
		}
	}

	ion-card.qr-code {
		max-width: 200px;
		margin-left: auto;
		margin-right: auto;
		padding: 0;

		ion-card-content {
			padding: $size_xs;
		}
	}

	ion-card.no-content {
		ion-card-content {
			img {
				width: 300px;
				max-width: 80%;
				margin: 0 auto;
				margin-bottom: $size_l;
				display: block;
			}
		}
	}

	--photo-loading-min-height: 120px;

	ion-thumbnail {
		border-radius: 8px;
		overflow: hidden;

		app-photo {
			height: 100%;
			--photo-loading-min-height: 100%;
		}

		app-photo-uploader ion-button {
			height: 100%;
			margin: 0;
			--border-radius: 0;
		}
	}

	.grecaptcha-badge {
		visibility: hidden;
	}

	.ellipsis {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	.middle {
		vertical-align: middle;
	}
}

@media (min-width: 768px) {
	:root {
		ion-modal {
			--width: 700px;
			--max-width: 90%;
			--height: 980px;
			--max-height: 90%;

			&.landscape {
				--width: 800px;
				--height: 600px;
			}
		}
		ion-alert {
			&.max-size {
				--width: 650px;
				--max-width: 85%;
				--height: 600px;
			}
		}
	}

	ion-button {
		&.button-block.mobile-block {
			width: auto;
			flex-grow: 0 !important;
			flex-basis: auto !important;
			display: inline-block;
		}
	}
}

@media (min-width: 780px) {
	:root {
		app-footer-wrapper > section,
		ion-router-outlet > .ion-page > ion-content {
			padding-inline: $size_l;
		}
	}
}

@media (min-width: 920px) {
	:root {
		app-footer-wrapper > section,
		ion-router-outlet > .ion-page > ion-content {
			padding-inline: $size_xl;
		}
	}
}

@media (min-width: 1080px) {
	:root {
		@include responsiveHeader(45px, 1090px);

		ion-header:not(.header-collapse-condense),
		.dark ion-header:not(.header-collapse-condense) {
			ion-toolbar {
				background-color: var(--ion-background-color);
				background-image: linear-gradient(
					to bottom,
					rgba(0, 0, 0, 0.2) 0%,
					rgba(0, 0, 0, 0) 100%
				);
				background-size: 100% !important;

				&,
				ion-card ion-item {
					--color: var(--ion-text-color);
				}
				ion-buttons {
					ion-button,
					ion-back-button {
						--ion-color-base: var(--ion-primary-color) !important;
					}
				}
			}
			&.header-collapse-condense-inactive:not(.static),
			&.home {
				ion-toolbar {
					background-color: transparent;
					background-image: var(--header-gradient, none);
					background-size: 100% !important;
				}
			}
			&.header-collapse-condense-inactive:not(.static) {
				ion-buttons {
					margin-top: 0px;
				}
			}
			&.header-collapse-none:not(.header-collapse-condense-inactive) {
				ion-toolbar {
					box-shadow: var(--shadow);
				}
				&.empty {
					ion-toolbar {
						box-shadow: none;
					}
				}
			}
			&.header-collapse-condense-inactive:not(.static) {
				ion-buttons {
					ion-button,
					ion-back-button {
						--ion-color-base: var(--ion-primary-color) !important;
					}
				}
			}
		}

		ion-tabs {
			ion-tab-bar {
				.logo {
					display: flex;
				}
			}
		}
	}
}

@media (min-width: 576px) {
	:root {
		ion-grid.inner {
			margin: calc(
				-1 * var(--ion-grid-column-padding-sm, var(--ion-grid-column-padding, 5px))
			);
		}
	}
}

@media (min-width: 768px) {
	:root {
		ion-grid.inner {
			margin: calc(
				-1 * var(--ion-grid-column-padding-md, var(--ion-grid-column-padding, 5px))
			);
		}
	}
}

@media (min-width: 992px) {
	:root {
		ion-grid.inner {
			margin: calc(
				-1 * var(--ion-grid-column-padding-lg, var(--ion-grid-column-padding, 5px))
			);
		}

		ion-segment.responsive {
			justify-content: start;

			&.md {
				border-bottom: 0.5px solid var(--ion-border-color);
			}
			ion-segment-button {
				flex-basis: auto;
				flex-grow: 0;

				ion-label {
					padding-left: $size_l;
					padding-right: $size_l;
				}
			}
			&.with-toolbar {
				padding-right: 200px;
			}
		}
		.segment-toolbar {
			width: 200px;
			margin-left: auto;
			margin-right: 0;
			margin-bottom: -47px;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
		.ios .segment-toolbar {
			height: 32px;
			line-height: 32px;
			margin-right: $size_m;
			margin-bottom: -32px;
			padding-top: 0;
			padding-bottom: 0;
		}
	}
}

@media (min-width: 1200px) {
	:root {
		ion-grid.inner {
			margin: calc(
				-1 * var(--ion-grid-column-padding-xl, var(--ion-grid-column-padding, 5px))
			);
		}
	}
}
