/** Ionic CSS Variables **/
:root {
  --ion-color-primary: #375075;
  --ion-color-primary-rgb: 55, 80, 117;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #304667;
  --ion-color-primary-tint: #4b6283;
  --ion-color-secondary: #788fab;
  --ion-color-secondary-rgb: 120, 143, 171;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #6a7e96;
  --ion-color-secondary-tint: #869ab3;
  --ion-color-tertiary: #ececec;
  --ion-color-tertiary-rgb: 236, 236, 236;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #d0d0d0;
  --ion-color-tertiary-tint: #eeeeee;
  --ion-color-success: #1e9e4f;
  --ion-color-success-rgb: 30, 158, 79;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #1a8b46;
  --ion-color-success-tint: #35a861;
  --ion-color-warning: #e7d002;
  --ion-color-warning-rgb: 231, 208, 2;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #cbb702;
  --ion-color-warning-tint: #e9d51b;
  --ion-color-danger: #9e301d;
  --ion-color-danger-rgb: 158, 48, 29;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #8b2a1a;
  --ion-color-danger-tint: #a84534;
  --ion-color-dark: #3a3a3a;
  --ion-color-dark-rgb: 58, 58, 58;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #333333;
  --ion-color-dark-tint: #4e4e4e;
  --ion-color-medium: #788fab;
  --ion-color-medium-rgb: 120, 143, 171;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #6a7e96;
  --ion-color-medium-tint: #869ab3;
  --ion-color-light: #ffffff;
  --ion-color-light-rgb: 255, 255, 255;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #e0e0e0;
  --ion-color-light-tint: #ffffff;
  --ion-color-text: #000000;
  --ion-color-text-rgb: 0, 0, 0;
  --ion-color-text-contrast: #ffffff;
  --ion-color-text-contrast-rgb: 255, 255, 255;
  --ion-color-text-shade: #000000;
  --ion-color-text-tint: #1a1a1a;
  --ion-color-step-50: #e3e8ee;
  --ion-color-step-100: #dde2e7;
  --ion-color-step-150: #d3d8dd;
  --ion-color-step-200: #bec2c9;
  --ion-color-step-250: #a4a8af;
  --ion-color-step-300: #999da3;
  --ion-color-step-350: #8e9297;
  --ion-color-step-400: #83868c;
  --ion-color-step-450: #787b80;
  --ion-color-step-500: #6d7075;
  --ion-color-step-550: #626569;
  --ion-color-step-600: #575a5d;
  --ion-color-step-650: #4c4e52;
  --ion-color-step-700: #414346;
  --ion-color-step-750: #37383a;
  --ion-color-step-800: #2c2d2f;
  --ion-color-step-850: #212223;
  --ion-color-step-900: #161617;
  --ion-color-step-950: #0b0b0c;
  --ion-background-color: #dae0e9;
  --ion-background-color-rgb: 218, 224, 233;
  --ion-text-color: #000000;
  --ion-text-color-rgb: 0, 0, 0;
  --ion-shadow-color: rgba(0, 0, 0, 0.3);
  --ion-border-color: rgba(0, 0, 0, 0.1);
  --ion-toolbar-background: var(--ion-color-primary);
  --ion-item-background: #ffffff;
  --ion-item-color: #000000;
  --ion-tab-bar-color: var(--ion-color-step-50);
  --ion-tab-bar-color-selected: #ffffff;
  --ion-tab-bar-color-focused: var(--ion-tab-bar-color-selected);
  --ion-tab-bar-background: var(--ion-color-secondary);
  --ion-overlay-background-color: #ffffff;
  --ion-overlay-background-color-rgb: 255, 255, 255;
  --wrapper-border-color: rgba(0, 0, 0, 0.1);
  --footer-background-color: var(--ion-color-secondary);
  --alpha-color-rgb: 0, 0, 0;
  --alpha-color: rgba(var(--alpha-color-rgb), 0.2);
  --shadow: 0px 2px 3px var(--ion-shadow-color);
  --shadow-buffer-top: 2px;
  --shadow-buffer-bottom: 5px;
  --default-border-radius: 8px;
  --header-gradient: linear-gradient(
  	to bottom,
  	rgba(0, 0, 0, 0.25) 0%,
  	rgba(0, 0, 0, 0.15) var(--ion-safe-area-top, 0%),
  	rgba(0, 0, 0, 0) 100%
  );
}

/*
 * Dark Theme Colors
 * -------------------------------------------
 */
body.dark {
  --ion-color-primary: #7a9eca;
  --ion-color-primary-rgb: 122, 158, 202;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #6b8bb2;
  --ion-color-primary-tint: #87a8cf;
  --ion-color-secondary: #4b5c74;
  --ion-color-secondary-rgb: 75, 92, 116;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #425166;
  --ion-color-secondary-tint: #5d6c82;
  --ion-color-tertiary: #606772;
  --ion-color-tertiary-rgb: 96, 103, 114;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #545b64;
  --ion-color-tertiary-tint: #707680;
  --ion-color-success: #87d3a2;
  --ion-color-success-rgb: 135, 211, 162;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #77ba8f;
  --ion-color-success-tint: #93d7ab;
  --ion-color-warning: #e0ca74;
  --ion-color-warning-rgb: 224, 202, 116;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #c5b266;
  --ion-color-warning-tint: #e3cf82;
  --ion-color-danger: #d37d79;
  --ion-color-danger-rgb: 211, 125, 121;
  --ion-color-danger-contrast: #000000;
  --ion-color-danger-contrast-rgb: 0, 0, 0;
  --ion-color-danger-shade: #ba6e6a;
  --ion-color-danger-tint: #d78a86;
  --ion-color-dark: #3a3a3a;
  --ion-color-dark-rgb: 58, 58, 58;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #333333;
  --ion-color-dark-tint: #4e4e4e;
  --ion-color-medium: #788fab;
  --ion-color-medium-rgb: 120, 143, 171;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #6a7e96;
  --ion-color-medium-tint: #869ab3;
  --ion-color-light: #ffffff;
  --ion-color-light-rgb: 255, 255, 255;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #e0e0e0;
  --ion-color-light-tint: #ffffff;
  --ion-color-text: #ffffff;
  --ion-color-text-rgb: 255, 255, 255;
  --ion-color-text-contrast: #000000;
  --ion-color-text-contrast-rgb: 0, 0, 0;
  --ion-color-text-shade: #e0e0e0;
  --ion-color-text-tint: #ffffff;
  --ion-color-step-50: #33363a;
  --ion-color-step-100: #3e4045;
  --ion-color-step-150: #484b4f;
  --ion-color-step-200: #535559;
  --ion-color-step-250: #5e6064;
  --ion-color-step-300: #696b6e;
  --ion-color-step-350: #737578;
  --ion-color-step-400: #7e8083;
  --ion-color-step-450: #898a8d;
  --ion-color-step-500: #949598;
  --ion-color-step-550: #9ea0a2;
  --ion-color-step-600: #a9aaac;
  --ion-color-step-650: #b4b5b7;
  --ion-color-step-700: #bfbfc1;
  --ion-color-step-750: #c9cacb;
  --ion-color-step-800: #d4d5d6;
  --ion-color-step-850: #dfdfe0;
  --ion-color-step-900: #eaeaea;
  --ion-color-step-950: #f4f4f5;
  --ion-background-color: #282b30;
  --ion-background-color-rgb: 40, 43, 48;
  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;
  --ion-shadow-color: rgba(0, 0, 0, 0.5);
  --ion-border-color: rgba(255, 255, 255, 0.1);
  --ion-item-background: #353d46;
  --ion-item-color: #ffffff;
  --ion-tab-bar-color: var(--ion-color-step-600);
  --ion-tab-bar-color-selected: #ffffff;
  --ion-tab-bar-color-focused: var(--ion-tab-bar-color-selected);
  --ion-tab-bar-background: var(--ion-color-secondary);
  --ion-overlay-background-color: #17191b;
  --ion-overlay-background-color-rgb: 23, 25, 27;
  --wrapper-border-color: rgba(255, 255, 255, 0.1);
  --footer-background-color: var(--ion-color-secondary);
  --alpha-color-rgb: 0, 0, 0;
  --alpha-color: rgba(var(--alpha-color-rgb), 0.3);
  --shadow: 0px 2px 3px var(--ion-shadow-color);
  --shadow-buffer-top: 2px;
  --shadow-buffer-bottom: 5px;
  --default-border-radius: 8px;
}